import React, { PureComponent, createRef } from 'react'
import Styled from 'styled-components'

import H3 from '../components/H3'
import SEO from '../components/seo'
import Hero from '../components/Hero'
import Badge from '../components/Badge'
import Layout from '../layouts/MainLayout'
import HideMobile from '../components/HideMobile'
import HideDesktop from '../components/HideDesktop'

import { CONTACT_LINK } from '../constant'

import theme from '../theme'
import Work1Src from '../assets/Work-1.png'
import Work2Src from '../assets/9.png'
import Work3Src from '../assets/MW3_POST_PARTNER.png'
import ClientsSrc from '../assets/clients.jpg'
import ClientsMobileSrc from '../assets/clients-mobile.jpg'
import MindProSrc from '../assets/mind-pro.png'
import WorkwiseSrc from '../assets/workwise.png'
import FormationsLiveSrc from '../assets/formations-live.png'
import ELearningSrc from '../assets/e-learning.png'
import FlowerCheckSrc from '../assets/flower-check.png'
import AppMindPreviewSrc from '../assets/app-mind-preview.png'
import HeaderSrc from '../assets/enterprise_header.png'
import HeaderHeadSrc from '../assets/enterprise_head.png'
import Mindfulness1Src from '../assets/Mindfulness-1.png'
import Mindfulness2Src from '../assets/Mindfulness-2.png'
import Mindfulness3Src from '../assets/Mindfulness-3.png'
import { ButtonGreen } from '../components/Button'


const mailto = `mailto:${CONTACT_LINK}`

const HeadImg = Styled.img`
  position: absolute;
  bottom: 50px;
  right: 8%;

  @media (max-width: ${theme.breakpoint}px) {
    position: static;
    display: block;
    margin: 0 auto;
    margin-top: 60px;
    width: 73%;
  }
`

const Caption = Styled.h3`
  display: block;
  margin-left: 0;
  margin-top: 40px;
  text-align: left;
  color: ${theme.color.alternativeWhite};
  font-size: 40px;
  line-height: 40px;

  @media (max-width: ${theme.breakpoint}px) {
    font-size: 25px;
    margin-top: 0;
    line-height: 32px;
    text-align: center;
  }
`

const BlueSection = Styled.section`
  z-index: 1;
  position: relative;
  padding-bottom: 0;
  background-color: ${theme.color.orange};
`

const H4Derived = Styled(H3)`
  font-size: 40px;
  margin-left: 0;
  color: ${theme.color.almostBlack};
`

const SectionContent = Styled.div`
  margin: 0 auto;
  width: 90%;
  max-width: ${theme.grid.fullWidth}px;

  @media (max-width: ${theme.breakpoint}px) {
    width: calc(100% - 60px);
    margin: 0 30px;
    text-align: center;
    padding-top: 40px;
  }
`

const BlueSectionContent = Styled(SectionContent)`
  padding-top: 160px;
  border-radius: 45px 45px 0 0;
  max-width: none;
  margin-top: -50px;
  width: 100%;
  background: ${theme.color.almostBlack};
  box-shadow: 0px 0px 35px rgba(104, 94, 94, 0.1);

  & > div {
    width: 100%;
    max-width: 1048px;
  }

  & ${Caption} {
    font-size: 40px !important;

    @media (max-width: ${theme.breakpoint}px) {
      margin-top: 0;
      text-align: center;
    }
  }

  @media (max-width: ${theme.breakpoint}px) {
    width: 100%;
    margin: 0;
    margin-top: -80px;

    & > div {
      margin-left: 0;
    }
  }
`

const CreamSection = Styled.div`
  top: -50px;
  padding-top: 140px;
  padding-bottom: 140px;
  position: relative;
  border-radius: 45px;
  background-color: ${theme.color.lightCream};
  box-shadow: 0 0 35px rgba(104, 94, 94, 0.1);

  @media (max-width: ${theme.breakpoint}px) {
    padding-top: 70px;
  }
`

const LittleSection = Styled.div`
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
  margin-top: ${props => props.workwise ? 55 : 80}px;
  padding-bottom: 100px;
  background: ${props => props.workwise ? theme.color.lightCream : "rgba(254, 249, 243, 0.7)"};
  border-radius: 45px;
  text-align: center;

  & > p {
    margin-top: 10px;
    font-size: 20px;
    color: ${theme.color.lightDarkBlue};
  }

  @media (max-width: ${theme.breakpoint}px) {
    margin-top: 40px;
    padding-bottom: 0;
  }
`

const MindProImg = Styled.img`
  margin-top: 80px;

  @media (max-width: ${theme.breakpoint}px) {
    margin-top: 50px;
    max-width: 220px;
  }
`

const MindProText = Styled.p`
  @media (max-width: ${theme.breakpoint}px) {
    font-size: 20px;
    line-height: 25px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 26px !important;
  }
`

const MailTo = Styled.a`
  text-decoration: none !important;
  font-size: 15px;
  text-transform: none;
  letter-spacing: normal;
  line-height: 56px;
  padding: 0 60px;
  display: block;
`

const ThreeCol = Styled.div`
  width: 100%;
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

  & > * {
    width: 30%;
    margin-top: 100px;
    max-width: 326px;
  }

  @media (max-width: ${theme.breakpoint}px) {
    justify-content: center;
    display: ${(props) => (props.onlyMobile ? 'none' : 'flex')};

    & > * {
      min-width: 326px;
      margin-top: 100px;

      &:first-child {
        margin-top: 40px;
      }
    }
  }
`

const ColImg = Styled.img`
  height: 100%;
`

const WorkImg = Styled.img`
  width: 100%;
  height: 562px;
  max-width: 384px;
`

const Work2Img = Styled.div`
  width: 100%;
  height: 562px;
  background-size: cover;
  background-position: 79% 50%;
`

const Work3Img = Styled(Work2Img)`
  background-position: 100% 50%;
`

const GascogneTitle = Styled.h3`
  font-size: 67px;
  font-weight: bold;
  margin-top: 225px;
  color: ${theme.color.alternativeWhite};
  font-family: ${theme.typography.gascogne};
  text-align: ${(props) => props.align || 'center'};
`

const MindForWorkSubtitle = Styled.p`
  margin-top: 50px;
  max-width: 550px;
  color: ${theme.color.alternativeWhite};
  font-size: ${theme.typography.bookSize}px;
  font-family: ${theme.typography.circularBook};
  text-align: ${(props) => props.align || 'left'};
  margin-bottom: ${(props) => (props.disableMarginBottom ? 0 : 105)}px;
  margin-left: ${(props) => typeof props.marginLeft === 'undefined' ? 60 : props.marginLeft}px;

  @media (max-width: ${theme.breakpoint}px) {
    margin-left: 0;
    font-size: 19px;
    text-align: left !important;
  }
`

const VideoContainer = Styled.section`
  padding: 200px 0;
  padding-top: 100px;

  @media (max-width: ${theme.breakpoint}px) {
    margin-top: 0px;
    padding: 50px 0;

    & h3 {
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }
`

const VideoContent = Styled.div`
  width: 100%;
  margin: 0 auto;
  color: ${theme.color.white};
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > * {
    width: calc(50% - 100px);
  }

  @media (max-width: ${theme.breakpoint}px) {
    display: block;

    & > * {
      width: auto;
    }
  }
`

const Video = Styled.div`
  min-width: 500px;

  & > * {
    width: 100%;
    height: 35vw;
  }


  @media (max-width: ${theme.breakpoint}px) {
    position: relative;
    left: -10px;
    left: 0;
    min-width: auto !important;

    & > * {
      height: 100vw;
      width: calc(100vw - 40px)
    }
  }
`

const VideoText = Styled.div`
  color: ${theme.color.white};
  font-size: 23px;
  line-height: 30px;

  @media (max-width: ${theme.breakpoint}px) {
    text-align: center;

    & > * {
      text-align: center !important;
      font-size: 20px;
      line-height: 25px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
`

const MindProInline = Styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 40px;
  justify-content: center;

  & > img {
    margin-right: 25px;
  }

  & > p {
    flex: 1;
    font-size: 23px;
    line-height: 25px;

    & span {
      font-size: 23px;
      font-weight: 100;
    }
  }

  @media (max-width: ${theme.breakpoint}px) {
    & > img {
      margin-right: 20px;
    }

    & > p,
    & span {
      font-weight: 200;
      font-size: 19px !important;
      line-height: 22px;
    }
  }
`


const MindProDualCol = Styled.section`
  display: flex;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 115px;

  & > img {
    width: 50%;
    margin-top: 20px;
    max-width: 280px;
    margin-right: 90px;
    max-height: 547px;
  }

  & > div {
    flex: 1;
    background: ${theme.color.lightCream};
    border-radius: 15px;
    padding: 60px;
    margin-bottom: 60px;
  }

  & ${MindProInline} {
    text-align: left;
    color: ${theme.color.black};

    & img {
      width: 28px;
      height: 28px;
    }

    &:first-child {
      margin-top: 0;

      p {
        font-weight: bold;
        color: ${theme.color.enterprise.green};
      }
    }
  }

  @media (max-width: ${theme.breakpoint}px) {
    margin-top: 70px;

    & > img {
      display: none;
    }

    & > div {
      margin-bottom: 0;
      border-radius: 0 0 45px 45px;
      padding-left: 25px;
      padding-right: 6px;
      padding-bottom: 25px;
    }

    & ${ButtonGreen} {
      margin-left: -19px;
      margin-top: 100px;
    }
  }
`

const WorkwiseDescription = Styled.div`
  display: flex;
  align-items: center;
  margin-top: 100px;
  justify-content: center;

  & img:first-child {
    height: 185px;
    margin-right: 30px;
  }

  & p {
    font-weight: 700;
    font-size: 115px;
    line-height: 30px;
  }

  & img:last-child {
    margin-left: 70px;
    height: 192px;
  }

  @media (max-width: ${theme.breakpoint}px) {
    display: block;
    margin-top: 50px;

    & img {
      max-width: 250px;
      margin-left: auto !important;
      margin-right: auto !important;
      height: auto !important;
    }
  }
`

const WorkwiseDualSection = Styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  max-width: 920px;
  margin 0 auto;
  margin-top: 60px;
  text-align: left;

  & > div {
    flex: 1;
    margin-bottom: 60px;
    padding-left: 60px;
    padding-top: 60px;
    padding-bottom: 60px;
    height: 274px;
    border-radius: 15px;
    background: ${theme.color.enterprise.lightOrange};

    & > p {
      font-size: 20px;
      line-height: 25px;
      color: ${theme.color.lightDarkBlue};

      &:first-child {
        font-size: 25px;
        line-height: 30px;
        font-weight: bold;
        margin-bottom: 10px;
        margin-bottom: 10px;
      }
    }

    &:first-child {
      margin-right: 40px;
    }
  }

  @media (max-width: ${theme.breakpoint}px) {
    display: block;

    & > div {
      margin-right: 0 !important;
      height: auto;
      padding-left: 30px;
      padding-right: 30px;
      margin-bottom: 30px;

      & > p {
        font-size: 19px;
        line-height: 22px;
        font-weight: 200;

        &:first-child {
          font-size: 22px;
          line-height: 27px;
        }
      }
    }
  }
`

const ButtonGreenContainer = Styled.div`
  margin: 0 auto;
  max-width: 600px;
  display: flex;
  justify-content: ${props => props.workwise ? "center" : "flex-end"};

  @media (max-width: ${theme.breakpoint}px) {
    margin-top: 80px;
    padding-bottom: 18px;
    margin-left: 10px;
  }
`

const ButtonGreenContainerMeditation = Styled(ButtonGreenContainer)`
  font-size: 15px;
  margin-top: 65px;
  justify-content: flex-start;

  @media (max-width: ${theme.breakpoint}px) {
    display: block;
    margin-top: 35px;
    margin-left: 10px;
    margin-right: auto;
    margin-bottom: 150px;
  }
`

const ClientsContainer = Styled.section`
  margin-top: 160px;
  background: ${theme.color.lightCream};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 45px 45px 0 0;
  padding-bottom: 100px;
  padding-top: 150px;

  @media (max-width: ${theme.breakpoint}px) {
    margin-top: 60px;
    padding-top: 85px;
  }
`

const ClientsTitle = Styled.h2`
  font-family: ${theme.typography.gascogne};
  font-size: 55px;
  line-height: 65px;
  display: block;
  text-align: center;
  color: ${theme.color.workwise.orange};

  @media (max-width: ${theme.breakpoint}px) {
    font-size: 40px;
    line-height: 47px;
  }
`

const ClientsTestimonial = Styled.div`
  margin: 0 auto;
  width: 90%;
  max-width: 1000px;
  text-align: center;
  margin-top: 54px;
  color: ${theme.color.dark};

  & ${HideMobile} {
    font-size: 25px;
    line-height: 32px;
  }

  & ${HideDesktop} {
    font-size: 20px;
    line-height: 25px;
  }

  & span {
    display: block;

    &.name {
      font-size: 24px;
      line-height: 29px;
      font-family: Roboto;
      font-weight: 400;
      margin-top: 100px;
    }

    &.job {
      margin-top: 5px;
      font-size: 20px;
      line-height: 25px;
    }

    &.enterprise {
      margin-top: 5px;
      font-size: 20px;
      line-height: 25px;
    }
  }

  @media (max-width: ${theme.breakpoint}px) {
    & span {
      &.name {
        margin-top: 50px;
        font-size: 20px;
        line-height: 25px;
      }

      &.job {
        font-size: 18px;
        line-height: 25px;
      }

      &.enterprise {
        font-size: 15px;
        line-height: 25px;
      }
    }
  }
`

const ClientsImageContainer = Styled.div`
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 50px;
  text-align: center;

  & img {
    width: 90%;
    max-width: 2000px;
  }

  @media (max-width: ${theme.breakpoint}px) {
    margin-top: 60px;
  }
`


const data = [
  {
    src: Work1Src,
    img: <WorkImg src={Work1Src} />,
    title: "L'application<br />Méditer avec Novotel",
    subtitle:
      "Une application pour méditer avant de s'endormir, au réveil, avant une réunion...",
  },
  {
    src: Work2Src,
    img: <Work2Img style={{ backgroundImage: `url(${Work2Src})` }} />,
    title: 'Méditer en avion<br />avec Air France',
    subtitle:
      'Des programmes de méditation créés par Christophe André et Eline Snel à bord des long courriers Air France. 3 millions de vues en 2 ans.',
  },
  {
    src: Work3Src,
    img: <Work3Img style={{ backgroundImage: `url(${Work3Src})` }} />,
    title: 'Meditation Week<br />avec Lov Organic',
    subtitle:
      'Une semaine de méditation dans des lieux exceptionnels. 1000 inscriptions dès le 1er jour',
  },
]

class EnterprisePage extends PureComponent {
  /* LIFECYCLE */

  /**
   * @constructor
   * @param {*} props - Properties of the PureComponent
   */
  constructor(props) {
    super(props)

    this.state = {
      current: {},
      activeIndex: 0,
    }

    this.items = createRef()
  }

  /**
   * @componentDidMount
   */
  componentDidMount() {
    if (window.innerWidth < theme.breakpoint) {
      this.interval = window.setInterval(this.onTouchMove, 500)
    }
  }

  /**
   * @componentWillUnmount
   */
  componentWillUnmount() {
    window.clearInterval(this.interval)
  }

  /* METHODS */

  onTouchMove = () => {
    const { current: items } = this.items

    if (items) {
      const widthRef = items.children[0].clientWidth
      const activeIndex = Math.ceil(items.scrollLeft / (widthRef + 30))

      this.setState({
        activeIndex,
        current: data[activeIndex],
      })
    }
  }

  /* RENDER */

  /**
   * @render
   * @returns {JSX}
   */
  render() {
    const { location } = this.props
    const { current, activeIndex } = this.state

    const VideoTextEdited = (
      <VideoText>
        <MindForWorkSubtitle marginLeft={0}>
          Qu’est ce que la mindfulness ?<br />
          Comment peut-elle améliorer la qualité de vie au travail ?<br />
          <br />
          Que propose l’application Mind ?<br />
          <br />
          Si vous souhaitez abonner vos équipes<HideMobile /> à l’application Mind, demandez nos tarifs à :<br />
          <br />
          <ButtonGreenContainerMeditation>
            <ButtonGreen><MailTo href={mailto}>contact@mind-app.io</MailTo></ButtonGreen>
          </ButtonGreenContainerMeditation>
        </MindForWorkSubtitle>
      </VideoText>
    )

    return (
      <Layout location={location}>
        <SEO title="Mind for work" />

        <Hero
          backgroundImage={HeaderSrc}
          title="Mind for work"
          subtitle="Méditez en entreprise avec Mind"
        >
          <HeadImg src={HeaderHeadSrc} />
        </Hero>

        <BlueSection id="about">
          <CreamSection>
            <SectionContent>
              <H4Derived>Les bienfaits<HideDesktop /> de la mindfulness<br />en entreprise</H4Derived>

              <ThreeCol>
                <Badge
                  disableBackground
                  img={<ColImg src={Mindfulness1Src} />}
                  title="Bien-être"
                  subtitle="La mindfulness permet<br />d'augmenter le sentiment<br />de bien-être."
                />
                <Badge
                  disableBackground
                  img={<ColImg src={Mindfulness2Src} />}
                  title="Travail d'équipe"
                  subtitle="L'intelligence émotionnelle<br />permet un meilleur travail<br />en équipe et un meilleur<br />leadership."
                />
                <Badge
                  disableBackground
                  img={<ColImg src={Mindfulness3Src} />}
                  title="Créativité"
                  subtitle="La pratique de la méditation<br />libère la créativité et<br />permet l'innovation."
                />
              </ThreeCol>
            </SectionContent>
          </CreamSection>

          <LittleSection>
            <MindProImg src={MindProSrc} />
            <MindProText>Offrez à vos équipes un accès Premium<HideMobile /> à l'application<HideDesktop /> de méditation Mind</MindProText>

            <MindProDualCol>
              <img src={AppMindPreviewSrc} />

              <div>
                <MindProInline>
                  <img src={FlowerCheckSrc} />
                  <p>Jusqu'à -70%<br /><span>pour les entreprises !</span></p>
                </MindProInline>

                <MindProInline>
                  <img src={FlowerCheckSrc} />
                  <p>3 programmes d'initiation</p>
                </MindProInline>

                <MindProInline>
                  <img src={FlowerCheckSrc} />
                  <p>Méditation quotidienne en direct</p>
                </MindProInline>

                <MindProInline>
                  <img src={FlowerCheckSrc} />
                  <p>+ 350 séances guidées<br />sur le sommeil, le stress,<br />la créativité etc.</p>
                </MindProInline>

                <MindProInline>
                  <img src={FlowerCheckSrc} />
                  <p>+ 120 podcasts</p>
                </MindProInline>

                <MindProInline>
                  <img src={FlowerCheckSrc} />
                  <p>Des Lives vidéos avec<br />les + grands experts</p>
                </MindProInline>

                <HideDesktop>
                  <ButtonGreen>
                    <a href="https://gift.mind-app.io/" target="_blank">En savoir +</a>
                  </ButtonGreen>
                </HideDesktop>
              </div>
            </MindProDualCol>

            <HideMobile>
              <ButtonGreenContainer>
                <ButtonGreen>
                  <a href="https://gift.mind-app.io/" target="_blank">En savoir +</a>
                </ButtonGreen>
              </ButtonGreenContainer>
            </HideMobile>
          </LittleSection>

          <LittleSection workwise>
            <MindProImg src={WorkwiseSrc} />
            <MindProText>Un programme de formation à la mindfulness<HideMobile /> à destination<HideDesktop /> des salariés</MindProText>

            <HideMobile>
              <WorkwiseDescription>
                <img src={FormationsLiveSrc} />
                <p>+</p>
                <img src={ELearningSrc} />
              </WorkwiseDescription>

              <WorkwiseDualSection>
                <div>
                  <p>14 HEURES</p>
                  <p>4 demi-journées de formation<br />interactive, en présentiel ou online,<br />alternant théorie, pratiques de<br />méditation et échanges entre<br />participants.</p>
                </div>

                <div>
                  <p>24 JOURS</p>
                  <p>24 séances, exercices,<br />enseignements et témoignages<br />à suivre quotidiennement via<br />une application mobile dédiée.</p>
                </div>
              </WorkwiseDualSection>
            </HideMobile>

            <HideDesktop>
              <WorkwiseDescription><img src={FormationsLiveSrc} /></WorkwiseDescription>

              <WorkwiseDualSection>
                <div>
                  <p>14 HEURES</p>
                  <p>4 demi-journées<br /> de formation interactive,<br /> en présentiel ou online,<br />alternant théorie, pratiques<br />de méditation et échanges<br />entre participants.</p>
                </div>
              </WorkwiseDualSection>

              <WorkwiseDescription><p>+</p></WorkwiseDescription>
              <WorkwiseDescription><img src={ELearningSrc} /></WorkwiseDescription>

              <WorkwiseDualSection>
                <div>
                  <p>24 JOURS</p>
                  <p>24 séances,<br />exercices, enseignements<br />et témoignages à suivre<br />quotidiennement via une<br />application mobile dédiée.</p>
                </div>
              </WorkwiseDualSection>
            </HideDesktop>

            <ButtonGreenContainer workwise>
              <ButtonGreen>
                <a href="/workwise">Découvrir</a>
              </ButtonGreen>
            </ButtonGreenContainer>
          </LittleSection>

          <ClientsContainer>
            <ClientsTitle>Nos clients</ClientsTitle>
            <ClientsTestimonial>
              <HideMobile>
                « Une expérience unique, qui enrichit l’expérience<br />
                humaine des collaborateurs et les aident dans leur contexte<br />
                professionnel au quotidien. A intégrer absolument dans le<br />
                catalogue de formations RH des entreprises ! »<br />
              </HideMobile>

              <HideDesktop>
                « Une expérience unique,<br />
                qui enrichit l’expérience humaine<br />
                des collaborateurs et les aident<br />
                dans leur contexte professionnel<br />
                au quotidien. A intégrer absolument<br />
                dans le catalogue de formations RH des entreprises ! »
              </HideDesktop>

              <span className="name">ALICE DUFLOT</span>
              <span className="job">Direction des Ressources Humaines</span>
              <span className="enterprise">AIR FRANCE</span>
            </ClientsTestimonial>

            <ClientsImageContainer>
              <HideMobile><img src={ClientsSrc} /></HideMobile>
              <HideDesktop><img src={ClientsMobileSrc} /></HideDesktop>
            </ClientsImageContainer>
          </ClientsContainer>

          <BlueSectionContent>
            <SectionContent>
              <Caption>Pourquoi méditer<br /> en entreprise ?</Caption>

              <VideoContainer>
                <VideoContent>

                  <Video>
                    <iframe
                      src="https://player.vimeo.com/video/395922222?title=0&byline=0&portrait=0"
                      frameBorder="0"
                      allow="autoplay; fullscreen"
                      allowFullScreen
                    />
                  </Video>

                  <HideMobile>{VideoTextEdited}</HideMobile>

                  <HideDesktop>{VideoTextEdited}</HideDesktop>
                </VideoContent>
              </VideoContainer>
            </SectionContent>
          </BlueSectionContent>

        </BlueSection>
      </Layout>
    )
  }
}

export default EnterprisePage
